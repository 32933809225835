// @ts-nocheck data state are not showing properly with typescript
import Vue from 'vue'
import {AddressInterface} from '~/components/filter/address-search.vue'
import {mapGetters} from 'vuex'
import {EVENT_CHANGE_ADDRESS, EVENT_CHANGE_DATA, EVENT_CHANGE_PERSONS, EVENT_SEARCH} from '~/util/event-bus'

export default Vue.extend({
  data(): {
    isAddressOpen: boolean;
    isCalendarOpen: boolean;
    isTimePickerOpen: boolean;
    isPersonOpen: boolean;
    hasLocalChanges: boolean;
  } {
    return {
      isAddressOpen: false,
      isCalendarOpen: false,
      isTimePickerOpen: false,
      isPersonOpen: false,
      hasLocalChanges: false,
    }
  },
  computed: {
    addressState: {
      get() {
        return this.filters.address
      },
      set(val: AddressInterface) {
        if (val !== this.addressState) {
          this.updateStore({
            address: val
          })

          // @ts-ignore
          this.$nuxt.$emit(EVENT_CHANGE_ADDRESS);

          this.hasLocalChanges = true;
        }
      }
    },
    calendarState: {
      get() {
        return this.filters.calendar
      },
      set(val: Date) {
        if (val !== this.calendarState) {
          this.updateStore({
            calendar: val
          })

          this.hasLocalChanges = true;
        }
      }
    },
    personState: {
      get() {
        return this.filters.person ?? 0
      },
      set(val: number) {
        if (val !== this.personState) {
          this.updateStore({
            person: val
          })

          // @ts-ignore
          this.$nuxt.$emit(EVENT_CHANGE_PERSONS);

          this.hasLocalChanges = true;
        }
      }
    },
    ...mapGetters({
      filters: 'filter/filter'
    })
  },
  methods: {
    flushLocalState() {
      this.$nuxt.$emit(EVENT_SEARCH, {});
      this.hasLocalChanges = false;
    },
    getDynamicValue(key: string): string | number | number[] | boolean | undefined {
      const dynamicFilters = this.filters.dynamicFilters ?? {}
      return dynamicFilters[key]
    },
    updateDynamicValue(key: string, value: string | boolean | number | number[]) {
      const dynamicFilters = {}

      this.$set(dynamicFilters, key, value);

      this.updateStore({
        dynamicFilters
      });

      this.$nuxt.$emit(EVENT_SEARCH, {});
      this.hasLocalChanges = false;
    },
    updateStore(payload: object) {
      this.$store.commit('filter/UPDATE_FILTERS', payload);
      this.$nuxt.$emit(EVENT_CHANGE_DATA, {});
    },
    openNext(previous: string) {
      this.isAddressOpen = false
      this.isCalendarOpen = false
      this.isPersonOpen = false

      if (previous === 'address') {
        this.isCalendarOpen = true
      } else if (previous === 'calendar') {
        this.isPersonOpen = true
      } else if (previous === 'person' && this.hasLocalChanges) {
        this.flushLocalState();
      }
    },
    openPrevious(screen: string) {
      this.isAddressOpen = false
      this.isCalendarOpen = false
      this.isPersonOpen = false

      if (screen === 'calendar') {
        this.isAddressOpen = true
      } else if (screen === 'person') {
        this.isCalendarOpen = true
      }
    },
  },
})
