
import Vue, {VueConstructor} from 'vue'
import NumberSelector from '~/components/ui/number-selector.vue'
import BaseFilter from '~/mixins/filter.mixin'

export default (Vue as VueConstructor<Vue & InstanceType<typeof BaseFilter>>).extend({
  name: 'FilterPerson',
  mixins: [BaseFilter],
  components: {NumberSelector},
  props: {
    showButton: {
      type: Boolean,
      default: false,
    },
    buttonTitle: {
      type: String,
      default: ''
    },
    minValue: {
      type: Number,
      default: 2
    },
    maxValue: {
      type: Number,
      default: 999,
    }
  },
  data() {
    return {
      persons: 0
    }
  },
  watch: {
    value: {
      handler(val: number): void {
        this.persons = val
      },
      immediate: true,
    },
    persons(val: number): void {
      this.updateValue(val)
    }
  },
  methods: {
    nextStep() {
      this.clickNext()
    }
  }
})
