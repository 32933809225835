
import Vue from 'vue';

export default Vue.extend({
  name: 'NumberSelector',
  props: {
    value: {
      default: 0,
      type: Number
    },
    minValue: {
      default: 0,
      type: Number
    },
    maxValue: {
      default: 999,
      type: Number
    }
  },
  computed: {
    disableMinValue(): boolean {
      return this.value <= this.minValue;
    },
    disableMaxValue(): boolean {
      return this.value >= this.maxValue;
    }
  },
  methods: {
    change(event: any) {
      let newVal = parseInt(event.target.value, 10);
      if (isNaN(newVal)) {
        newVal = 2;
      }

      if (newVal < this.minValue) {
        newVal = this.minValue;
      }

      if (newVal > this.maxValue) {
        newVal = this.maxValue;
      }

      this.$emit('input', newVal)
    },
    increaseValue() {
      if (this.disableMaxValue) {
        return;
      }

      let newVal = this.value + 1;

      if (newVal < this.minValue) {
        newVal = this.minValue;
      }

      this.$emit('input', newVal)
    },
    decreaseValue() {
      if (this.disableMinValue) {
        return;
      }

      this.$emit('input', this.value - 1)
    }
  }
});
