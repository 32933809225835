
import Vue, {VueConstructor} from 'vue'
import BaseFilter from '~/mixins/filter.mixin'
import ResponsiveMixin from '~/mixins/responsive.mixin'

export default (Vue as VueConstructor<Vue & InstanceType<typeof BaseFilter> & InstanceType<typeof ResponsiveMixin>>).extend({
  name: 'FilterCalendar',
  mixins: [BaseFilter, ResponsiveMixin],
  props: {
    blockedDates: {
      type: Array,
      required: false,
    }
  },
  data(): {
    selectedDate: Date | undefined
  } {
    return {
      selectedDate: undefined
    }
  },
  watch: {
    value: {
      handler(val: number | undefined): void {
        if (val) {
          const newDate = new Date(val);
          this.selectedDate = newDate;
          this.update(newDate);
        } else {
          this.selectedDate = undefined;
          this.update(undefined);
        }
      },
      immediate: true,
    }
  },
  methods: {
    update(val: Date | undefined) {
      if (this.selectedDate !== val) {
        this.selectedDate = val;

        if (val) {
          if (!this.isMobile) {
            this.nextStep();
          }
        }
      }
    },
    nextStep() {
      this.updateValue((this.selectedDate as Date).getTime())
      this.clickNext()
    }
  }
})
