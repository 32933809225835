import { render, staticRenderFns } from "./person.vue?vue&type=template&id=c9e0c884"
import script from "./person.vue?vue&type=script&lang=ts"
export * from "./person.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UiIconInfo: require('/vercel/path0/components/ui/icon/info.vue').default,UiButton: require('/vercel/path0/components/ui/button.vue').default,FilterBaseFilterCard: require('/vercel/path0/components/filter/base-filter-card.vue').default})
