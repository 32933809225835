
import Vue from 'vue'
// @ts-ignore
import nl from 'vuejs-datepicker/dist/locale/translations/nl'
// @ts-ignore
import en from 'vuejs-datepicker/dist/locale/translations/en'

export default Vue.extend({
  name: 'UiDatePicker',
  props: {
    value: {
      type: Date,
      required: false,
    },
    blockedDates: {
      type: Array,
      required: false,
    }
  },
  data() {
    return {
      nl: nl,
      en: en
    }
  },
  methods: {
    selectedDate(date: Date) {
      this.$emit('input', date)
    }
  }
})
